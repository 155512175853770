const orgValidator = {
  methods: {
    getChooseOrgIds(value) {
      console.error('orgValidator getChooseOrgIds undefined!')
      return undefined
    },
    getOrganizationsData() {
      console.error('orgValidator getOrganizationsData undefined!')
      return undefined
    },
    /**
     * 验证选择 同一用户在同一单位下只允许设置一个部门
     * @param rule
     * @param value 选中的部门ID
     * @param callback
     */
    orgValidator(rule, value, callback) {
      const chooses = this.getChooseOrgIds(value)
      if (chooses) {
        const unitIds = []
        for (let i = 0; i < chooses.length; i++) {
          const key = chooses[i]
          if (key) {
            const unitId = this.findOrgById(this.getOrganizationsData(), key)
            if (unitIds.indexOf(unitId) === -1) {
              unitIds.push(unitId)
            } else {
              callback('请检查所选部门，同一用户在同一单位下只允许设置一个部门')
              return
            }
          }
        }
      }
      callback()
    },
    /**
     * 部门树中根据ID获取部门对象
     */
    findOrgById(children, key) {
      for (let i = 0; i < children.length; i++) {
        const orgObj = children[i]
        if (orgObj.key === key + '') {
          return this.getOrgUnitId(orgObj)
        } else if (orgObj.children && orgObj.children.length > 0) {
          const temp = this.findOrgById(orgObj.children, key)
          if (temp) return temp
        }
      }
      return undefined
    },
    /**
     * 获取指定部门对象的单位ID
     */
    getOrgUnitId(orgObj) {
      if (orgObj.attribute === 'unit') {
        return orgObj.id
      } else {
        return orgObj.unitId
      }
    },
  },
}

export default orgValidator
