<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">用户管理</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button type="default" icon="import" @click="importInfo">导入</a-button>
        <a-button class="m-l-12" icon="cloud-download" type="default" @click="downFile">下载导入模板</a-button>
        <a-button
          class="m-l-12"
          icon="plus"
          type="primary"
          @click="handleAdd"
          v-auth="auth_system.USER_CREATE_AND_UPDATE"
          >创建用户
        </a-button>
      </div>
    </div>
    <!-- ↓↓↓表格↓↓↓ -->
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="用户信息">
            <a-input
              v-model="searchParams.q"
              @pressEnter="search"
              style="width: 250px;"
              placeholder="姓名模糊查询，用户名、手机号精确查询"
            />
          </a-form-item>
          <a-form-item label="组织机构">
            <a-spin v-if="orgFetching" style="margin-left: 150px;" slot="notFoundContent" size="small" />
            <a-tree-select
              style="width: 350px;"
              allowClear
              :treeData="organizations"
              v-model="searchParams.oIdStr"
              @change="orgSelectChangeHandle"
              @pressEnter="orgSelectChangeHandle"
              placeholder="请选择组织机构"
            >
            </a-tree-select>
          </a-form-item>
          <a-form-item label="职务" v-show="jobs.length > 0">
            <a-select
              allowClear
              showSearch
              optionFilterProp="label"
              v-model="searchParams.jId"
              placeholder="请选择职务"
              @change="search"
              @pressEnter="search"
            >
              <a-spin v-if="jobFetching" style="margin-left: 65px;" slot="notFoundContent" size="small" />
              <a-select-option v-for="item in jobs" :key="item.key" :value="item.key" :label="item.name"
                >{{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :scroll="{ x: 1600 }"
      >
        <span slot="serial" slot-scope="text, record, index">{{
          searchParams.number * searchParams.size + index + 1
        }}</span>
        <span slot="orgJobs" slot-scope="text, record">
          <template v-for="item in record.organizationJobs">
            <a-tag style="cursor: text; margin: 3px;" color="blue"
              >{{ item.organizationFullName }}
              <template v-if="item.jobId"> <a-divider type="vertical" />{{ item.jobName }} </template>
            </a-tag>
          </template>
        </span>
        <span slot="operation" slot-scope="text, record">
          <template>
            <a-button
              type="default"
              shape="circle"
              icon="cluster"
              size="small"
              @click="handleOpenOrgJobModal(record)"
            ></a-button>
            <a-divider type="vertical" />
            <a-button
              v-auth="auth_system.USER_CREATE_AND_UPDATE"
              type="default"
              shape="circle"
              icon="edit"
              size="small"
              @click="handleEdit(record.id)"
            ></a-button>
            <a-divider type="vertical" v-auth="auth_system.USER_RESET_PASSWORD" />
            <a-popconfirm title="确定重置密码操作吗?" @confirm="() => handleResetPassword(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: orange;" />
              <a-button
                v-auth="auth_system.USER_RESET_PASSWORD"
                type="default"
                shape="circle"
                icon="key"
                size="small"
              ></a-button>
            </a-popconfirm>
            <a-divider type="vertical" v-auth="auth_system.USER_DELETE" />
            <a-button
              v-auth="auth_system.USER_DELETE"
              type="danger"
              @click="handleDelete(record.id, '用户信息删除后不可恢复。')"
              shape="circle"
              icon="delete"
              size="small"
            ></a-button>
            <!--<a-divider type="vertical"/>
            <a-button v-auth="auth_system.USER_VIEW" type="dashed" shape="circle" icon="question" size="small"
                      @click="viewUser(record)"></a-button>-->
          </template>
        </span>
      </a-table>
    </a-card>
    <a-modal
      title="用户添加"
      v-model="addModal"
      :maskClosable="false"
      okText="添加"
      cancelText="取消"
      width="1060px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleAddOk"
    >
      <user-form
        type="add"
        ref="addRef"
        @addSuccess="addSuccess"
        @addError="addError"
        @submitStart="handleConfirmStart"
      ></user-form>
    </a-modal>
    <a-modal
      title="用户修改"
      v-model="editModal"
      :maskClosable="false"
      okText="修改"
      cancelText="取消"
      width="1060px"
      :confirmLoading="confirmButtonStatus"
      @ok="handleEditOk"
    >
      <user-form
        type="edit"
        ref="editRef"
        @editSuccess="editSuccess"
        @editError="editError"
        @submitStart="handleConfirmStart"
      ></user-form>
    </a-modal>
    <a-modal
      :title="chooseData.name + '的组织机构职务分配'"
      v-model="orgJobModal"
      :maskClosable="false"
      :footer="null"
      width="1060px"
    >
      <user-job-list
        ref="userOrgJobRef"
        :chooseData="chooseData"
        @addSuccess="handleOrgJobAddSuccess"
        @editSuccess="handleOrgJobEditSuccess"
        @deleteSuccess="handleOrgJobDeleteSuccess"
      ></user-job-list>
    </a-modal>
    <a-modal title="导入" v-model="importModel" :footer="null" :maskClosable="false" width="480px">
      <import-form :import-url="importUrl"></import-form>
    </a-modal>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '../../../api/service.url'
import entityCRUD from '../../../views/common/mixins/entityCRUD'
import PageLayout from '../../../components/page/PageLayout'
import UserForm from './UserForm'
import UserJobList from './UserJobList.vue'
import ImportForm from '../../base/ImportForm'

export default {
  name: 'UserList',
  components: { PageLayout, UserForm, UserJobList, ImportForm },
  mixins: [entityCRUD],
  data() {
    return {
      //导入
      importModel: false,
      entityBaseUrl: SERVICE_URLS.user,
      importUrl: SERVICE_URLS.user.import,
      searchParams: {
        q: '',
        oIdStr: undefined,
        jId: undefined,
        number: '0',
        size: '10',
        direction: 'DESC',
        order: 'dateCreated',
      },
      organizations: [],
      jobs: [],
      initColumns: [
        {
          title: '#',
          align: 'center',
          fixed: 'left',
          scopedSlots: { customRender: 'serial' },
          width: 70,
        },
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center',
          sorter: true,
          fixed: 'left',
          key: 'name',
          width: 150,
        },
        // {
        //   title: '身份证号',
        //   dataIndex: 'idCard',
        //   sorter: true,
        //   align: 'center',
        //   key: 'idCard',
        //   width: 250
        // },
        {
          title: '用户名',
          dataIndex: 'username',
          align: 'center',
          key: 'username',
          width: 150,
        },
        {
          title: '手机号码',
          dataIndex: 'phone',
          align: 'center',
          width: 150,
          key: 'phone',
        },
        {
          title: '微信',
          dataIndex: 'wechat',
          align: 'center',
          width: 150,
          key: 'wechat',
        },
        {
          title: 'QQ',
          dataIndex: 'qq',
          align: 'center',
          width: 150,
          key: 'qq',
        },
        {
          title: '组织职务',
          align: 'left',
          scopedSlots: { customRender: 'orgJobs' },
          width: 350,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 188,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      orgJobModal: false,
      // search fetch status
      orgFetching: true,
      jobFetching: true,
      chooseData: {},
    }
  },
  created() {
    this.loadOrgList()
  },
  methods: {
    importInfo() {
      this.importModel = true
    },
    downFile() {
      window.open('/template/user_import.xlsx')
    },
    loadOrgList() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        loading: 'orgFetching',
        noTips: true,
        success: (data) => {
          this.organizations = data.body
        },
      })
    },
    loadJobList(value) {
      if (value) {
        this.$http(this, {
          url: SERVICE_URLS.earlBase.job.selectByOrg,
          data: {
            orgId: value,
          },
          loading: 'jobFetching',
          noTips: true,
          success: (data) => {
            this.jobs = data.body
          },
        })
      }
    },
    // 部门变化后，加载职务以及搜索
    orgSelectChangeHandle(value) {
      this.loadJobList(value)
      this.search()
    },
    handleOpenOrgJobModal(record) {
      this.orgJobModal = true
      this.chooseData = record
      this.$nextTick(() => {
        this.$refs.userOrgJobRef.search()
      })
    },
    handleOrgJobAddSuccess(data) {
      this.chooseData.organizationJobs.push(data)
    },
    handleOrgJobEditSuccess(data) {
      for (let i = 0; i < this.chooseData.organizationJobs.length; i++) {
        const temp = this.chooseData.organizationJobs[i]
        if (temp.id === data.id) {
          temp.jobId = data.jobId
          temp.jobName = data.jobName
          break
        }
      }
    },
    handleOrgJobDeleteSuccess(id) {
      for (let i = 0; i < this.chooseData.organizationJobs.length; i++) {
        if (this.chooseData.organizationJobs[i].id === id) {
          this.chooseData.organizationJobs.splice(i, 1)
          break
        }
      }
    },
    search() {
      this.$http(this, {
        url: this.searchUrl,
        noTips: true,
        data: this.searchParams,
        loading: 'tableLoading',
        success: (data) => {
          this.tableData = data.body.content
          this.pagination.total = data.body.totalElements
          this.pagination.current = data.body.number + 1
          this.pagination.pageSize = data.body.size
          this.searchParams.size = data.body.size
          this.searchParams.number = data.body.number
        },
      })
    },
    /*添加*/
    handleAdd() {
      this.addModal = true
      this.$nextTick(() => {
        this.$refs['addRef'].loadOrgSelect()
      })
    },
    resetForm() {
      this.jobs = []
      this.searchParams = {
        q: '',
        oIdStr: undefined,
        jId: undefined,
        number: '0',
        size: '10',
        direction: 'DESC',
        order: 'dateCreated',
      }
      this.search()
    },
    handleResetPassword(id) {
      // console.log('重置密码', item)
      this.$http(this, {
        url: SERVICE_URLS.user.reset,
        params: {
          id: id,
        },
        successTipsContent: '密码重置成功',
        success: () => {},
      })
    },
  },
}
</script>
